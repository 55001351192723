import { faClose, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const CallIdShareBanner = ({callId}) => {

    const [isClosed, setIsClosed] = useState(false);

    const copyCallId = () => {
        navigator.clipboard.writeText(callId);
    }

    let content: JSX.Element | null;
    if (!isClosed) {
        content = (
            <div className="alert-container">
                <div className="alert alert-scondary">
                    <div className="d-flex align-items-start justify-content-between w-100">
                        <div>
                            <p>Share this call ID with the person you want to call.</p>
                            <p className="mb-0"><strong>Call ID:</strong> {callId} <button onClick={copyCallId} className="btn-icon"><FontAwesomeIcon icon={faCopy} /></button></p>
                        </div>
                        <button type="button" className="btn btn-link" onClick={() => setIsClosed(true)} title="Close"><FontAwesomeIcon icon={faClose} /></button>
                    </div>
                </div>
            </div>
        );
    } else {
        content = null;
    }

    return (
        content
    );
}

export default CallIdShareBanner;