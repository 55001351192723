import { FormEvent, useEffect, useRef, useState } from 'react';
import { iceServers } from '../webrtc.config';
import { addDoc, collection, doc, DocumentReference, DocumentSnapshot, getDoc, onSnapshot, query, QuerySnapshot, setDoc } from '@firebase/firestore';
import { db } from '../firebase';
import './video-chat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import CallIdShareBanner from './call-id-share-banner';


const VideoChat = () => {

    const localVideoRef = useRef<HTMLVideoElement | null>(null);
    const remoteVideoRef = useRef<HTMLVideoElement | null>(null);

    const [localStream, setLocalStream] = useState<MediaStream | null>(null);
    const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);

    const [peerConnection, setPeerConnection] = useState<RTCPeerConnection | null>(null);

    const [callId, setCallId] = useState<string | null>('');
    const [answerCallId, setAnswerCallId] = useState<string | null>('');

    const [joinError, setJoinError] = useState<string | null>(null);
    const [gumError, setGumError] = useState<string | null>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false); // Track if video is playing
    const [showShareBanner, setShowShareBanner] = useState<boolean>(false); // Track if share banner is showing
    const [showVideos, setShowVideos] = useState<boolean>(false); // Track if videos are showing
    const [remoteStreamPlaying, setRemoteStreamPlaying] = useState<boolean>(false); // Track if remote stream is playing

    const startACall = async () => {
        setShowVideos(true);
        setShowShareBanner(true);
        startVideo('offer');
    }

    const startVideo = async (type: string) => {

        // clear existing error message
        setGumError('');

        let localStream: MediaStream;
        try {
            localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
            localVideoRef.current!.srcObject = localStream;
            setLocalStream(localStream);

            const remoteStream = new MediaStream();
            setRemoteStream(remoteStream);
            if (remoteVideoRef.current) {
                remoteVideoRef.current.srcObject = remoteStream;
            }

            const peerConnection = new RTCPeerConnection(iceServers);
            localStream.getTracks().forEach((track) => {
                console.log('adding track', track);
                peerConnection.addTrack(track, localStream);
            });

            peerConnection.ontrack = (event) => {
                console.log('ontrack', event);
                event.streams.forEach(stream => {
                    stream.getTracks().forEach(track => {
                        remoteStream.addTrack(track);
                    });

                });
                if (remoteVideoRef.current) {
                    remoteVideoRef.current.srcObject = remoteStream;
                    setRemoteStreamPlaying(true);
                    setShowShareBanner(false);
                }
            };

            setPeerConnection(peerConnection);
            setIsPlaying(true);

            if (type === 'offer') {
                createOffer(peerConnection);
            } else {
                answerCall(peerConnection);
            }

        } catch(err) {
            console.error('Error accessing media devices.', err);
            setGumError('Error accessing media devices. Please allow access to camera and microphone.');
            return;
        }

    }

    const createOffer = async (peerConnection: RTCPeerConnection) => {

        console.log('peerConnection', peerConnection)

        const myCollectionRef = collection(db, "calls");
        const docRef = await addDoc(myCollectionRef, {});

        console.log(docRef.id);

        setCallId(docRef.id);
        // setCallIsCreated(true);

        addDoc(collection(db, 'calls', docRef.id, 'offerCandidates'), {});

        peerConnection!.onicecandidate = (event) => {
            if (event.candidate) {
              addDoc(collection(db, 'calls', docRef.id, 'offerCandidates'), event.candidate.toJSON());
            }
        };

        const offerDescription = await peerConnection!.createOffer();
        await peerConnection!.setLocalDescription(offerDescription);

        const offer = {
            sdp: offerDescription.sdp,
            type: offerDescription.type,
        };

        const callDoc = doc(db, 'calls', docRef.id);

        setDoc(callDoc, { offer }, { merge: true });

        setShowShareBanner(true);

        onSnapshot(callDoc, (snapshot: DocumentSnapshot) => {
            const data = snapshot.data();
            if (!peerConnection!.currentRemoteDescription && data?.['answer']) {
                const answerDescription = new RTCSessionDescription(data?.['answer']);
                peerConnection!.setRemoteDescription(answerDescription);
            }
        });

        onSnapshot(query(collection(db, 'calls', docRef.id, 'answerCandidates')), (snapshot: QuerySnapshot) => {
            snapshot.docChanges().forEach((change) => {
                console.log('change', change)
                if (change.type === 'added' && change.doc.data()['sdpMid']) {
                    const candidate = new RTCIceCandidate(change.doc.data());
                    peerConnection!.addIceCandidate(candidate);
                }
            });
        });

    }

    const joinCall = async (e: FormEvent) => {

        e.preventDefault();

        // clear existing error message
        setJoinError('');

        if (!answerCallId) {
            setJoinError('Please enter a valid call id');
            return;
        }

        setShowVideos(true);

        startVideo('answer');

    }

    const answerCall = async (pc: RTCPeerConnection) => {

        console.log('Answering call', answerCallId);

        const callDoc: DocumentReference = doc(db, 'calls', answerCallId);
        const docRef: DocumentSnapshot = await getDoc(callDoc);
        const callData = docRef.data();
        if (!callData) {
            setJoinError('Call not found');
            return;
        }

        // const pc: RTCPeerConnection = peerConnection;
        console.log('pc', pc);
        if (!pc) {
            setJoinError('Peer connection not found');
            return;
        }

        pc.onicecandidate = event => {
          if (event.candidate) {
            addDoc(collection(db, 'calls', answerCallId, 'answerCandidates'), event.candidate.toJSON());
          }
        };

        const offerDescription = callData['offer'];
        await pc.setRemoteDescription(new RTCSessionDescription(offerDescription));

        const answerDescription = await pc.createAnswer();
        await pc.setLocalDescription(answerDescription);

        const answer = {
          type: answerDescription.type,
          sdp: answerDescription.sdp,
        };

        // add answer to the collection
        setDoc(callDoc, { answer }, { merge: true });

        onSnapshot(query(collection(db, 'calls', answerCallId, 'offerCandidates')), (snapshot: QuerySnapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added' && change.doc.data()['sdpMid']) {
              const candidate = new RTCIceCandidate(change.doc.data());
              pc.addIceCandidate(candidate);
            }
          });

        });

    }

    const hangUp = async () => {

        // stop camera
        localStream?.getTracks().forEach(track => track.stop());
        localVideoRef.current!.srcObject = null;

        // stop remote stream
        remoteStream?.getTracks().forEach(track => track.stop());
        remoteVideoRef.current!.srcObject = null;

        setIsPlaying(false);
        // setCallIsCreated(false);
        setShowShareBanner(false);
        setRemoteStreamPlaying(false);

    }

    useEffect(() => {
        // startVideo();
    }, []);

    let content: JSX.Element;
    if (showVideos) {
        content = (
            <div className="videos max-window">
                <video ref={localVideoRef} className={remoteStreamPlaying ? 'thumbnail-video' : 'full-video'} autoPlay playsInline muted />
                <video ref={remoteVideoRef} className={remoteStreamPlaying ? 'full-video' : 'd-none'} autoPlay playsInline />
                {showShareBanner && callId ? <CallIdShareBanner callId={callId} /> : ''}
                <div className="button-controls d-flex justify-content-center w-100">
                    <button type="button" className="btn btn-danger me-2" onClick={hangUp} disabled={!isPlaying}>
                        <FontAwesomeIcon icon={faPhoneSlash} />
                    </button>
                </div>
            </div>
        )
    } else {
        content = (
            <div className="container d-flex justify-content-center align-items-center flex-column pt-5">
                <div className="w-100" style={{maxWidth: "300px"}}>
                    <div className="d-flex flex-column align-items-center w-100 mb-5">
                        <h3 className="text-center">Create a Call</h3>
                        <button type="button" className="btn btn-primary me-2 my-3 w-100" onClick={() => startACall()} disabled={isPlaying}>Start a Call</button>
                    </div>
                    <form onSubmit={(e) => joinCall(e)} className="w-100">
                        <h3 className="text-center">Join a Call</h3>
                        <div className="mt-3">
                            <input type="text" placeholder="Enter call id" required onChange={(e) => setAnswerCallId(e.target.value)} className="me-2 form-control" />
                            <button type="submit" className="btn btn-primary text-nowrap mt-3 w-100" disabled={!answerCallId}>Join Call</button>
                        </div>
                        {joinError ? <p className="text-danger mt-3">{joinError}</p> : ''}
                    </form>
                </div>
            </div>
        );
    }

    return (
        content
        // <div className="videos">
        //     <video ref={localVideoRef} className="local-video" autoPlay playsInline />
        //     <video ref={remoteVideoRef} className="remote-video" autoPlay playsInline muted />
        //     <div className="button-controls mt-3 d-flex justify-content-center w-100">
        //         <button type="button" className="btn btn-primary me-2" onClick={createOffer} disabled={!isPlaying || (isPlaying && callIsCreated)}>Create Call</button>
        //         <button type="button" className="btn btn-danger me-2" onClick={hangUp} disabled={!isPlaying}>
        //             <FontAwesomeIcon icon={faPhoneSlash} />
        //         </button>
        //         <button type="button" className="btn btn-secondary" onClick={hangUp} disabled={!isPlaying}>
        //             <FontAwesomeIcon icon={faVolumeMute} />
        //         </button>
        //     </div>
        // </div>
        // <div className="container">
        //     <div className="row">
        //         <div className="ps-lg-0 mb-5 mb-md-0 col-12 col-md-6">
        //             <video ref={localVideoRef} className="local-video" autoPlay playsInline />
        //             <div className="button-controls mt-3">
        //                 {/* <button type="button" className="btn btn-primary me-2" onClick={startVideo} disabled={isPlaying}>Start Video</button> */}
        //                 <button type="button" className="btn btn-primary me-2" onClick={createOffer} disabled={!isPlaying || (isPlaying && callIsCreated)}>Create Call</button>
        //                 <button type="button" className="btn btn-danger" onClick={hangUp} disabled={!isPlaying}>Hang Up</button>
        //             </div>
        //             {callId ? <p className="mt-3 mb-0"><strong>Call ID:</strong> {callId}</p> : ''}
        //             {gumError ? <p className="text-danger mt-3 mb-0">{gumError}</p> : ''}
        //         </div>
        //         <div className="pe-lg-0 col-12 col-md-6">
        //             <video ref={remoteVideoRef} className="remote-video" autoPlay playsInline muted />
        //             <form onSubmit={(e) => joinCall(e)}>
        //                 <div className="d-flex mt-3">
        //                     <input type="text" placeholder="Enter call id" required onChange={(e) => setAnswerCallId(e.target.value)} className="me-2 form-control" />
        //                     <button type="submit" className="btn btn-primary text-nowrap" disabled={!isPlaying}>Join Call</button>
        //                 </div>
        //                 {joinError ? <p className="text-danger mt-3">{joinError}</p> : ''}
        //             </form>
        //         </div>
        //     </div>
        //     <div className="row mt-3">
        //         <div className="col-12 col-md-6">
        //             <h3 className="font-bold">Creating a Call</h3>
        //             <ol className="list-decimal list-inside">
        //                 <li>Click Start Video to start your stream</li>
        //                 <li>Click Create Call offer to generate a call id</li>
        //                 <li>Share the room id with the person you want to connect with</li>
        //             </ol>
        //         </div>
        //         <div className="col-12 col-md-6">
        //             <h3 className="font-bold">Joining a Call</h3>
        //             <ol className="list-decimal list-inside">
        //                 <li>Click Start Video to start your stream</li>
        //                 <li>Enter the call id in the field above and Click Join</li>
        //             </ol>
        //         </div>
        //     </div>
        // </div>
    );

};

export default VideoChat;