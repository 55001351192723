import { FormEvent, useState } from 'react';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "@firebase/auth";
import { firebaseConfig } from '../firebase';
import { initializeApp } from '@firebase/app';


const LoginForm = ({ onLogin }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    initializeApp(firebaseConfig);
    const auth = getAuth();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!email || !password) {
            setError('Enter a valid email and password');
            return;
        }
        
    // authenticate user in firebase auth
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        console.log('User signed in');
        onLogin(true);
      })
      .catch((error) => {
        setError('Invalid email or password');
        console.error(error);
      });
    };

    return (
        <div className='row justify-content-center'>
            <form onSubmit={handleSubmit} className='col-6 col-sm-12 col-md-4  mt-5'>
                {error && <p className='text-danger'>{error}</p>}
                <div className='form-group'>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        placeholder='Email'
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className='form-control'
                    />
                </div>
                <div className='form-group mt-3'>
                    <input
                        type="password"
                        id="password"
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className='form-control'
                    />
                </div>
                <button type="submit" className='mt-3 btn btn-primary w-100'>Login</button>
            </form>
        </div>
    );
};

export default LoginForm;