import SiteHeader from './components/site-header';
import LoginForm from './components/login-form';
import VideoChat from './components/video-chat';
import { useState } from "react";
import './App.css';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { firebaseConfig } from './firebase';
import { initializeApp } from '@firebase/app';

function App() {

  function loginUpdate(isLoggedIn) {
    setIsLoggedIn(isLoggedIn);
  }

  initializeApp(firebaseConfig);
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setIsLoggedIn(true);
    }
  });

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  let content: JSX.Element;
  if (isLoggedIn) {
    content = <VideoChat />;
  } else {
    content = <LoginForm onLogin={loginUpdate} />;
  }

  return (
    <div className="app max-window">
      {/* <SiteHeader /> */}
      {/* <main className='container pt-4'> */}
        {content}
      {/* </main> */}
    </div>
  );
}

export default App;
