 // Import the functions you need from the SDKs you need
import { initializeApp } from "@firebase/app";
import { getFirestore } from "@firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyC-fnUA41xKFUp7u19bArqsZg1fXeJKCKU",
  authDomain: "webrtc-video-chat-82d91.firebaseapp.com",
  projectId: "webrtc-video-chat-82d91",
  storageBucket: "webrtc-video-chat-82d91.appspot.com",
  messagingSenderId: "708583970309",
  appId: "1:708583970309:web:36ad0f9f7d59e2463cae6c"
};
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
